import { Navigate, Outlet } from "react-router-dom";

import { type AvailableRoles, usePermissions } from "@/providers/PermissionsProvider";

type ProtectedRouteProps = {
  requiredRoles: AvailableRoles[];
  redirectTo: string;
};

const ProtectedRoute = ({ requiredRoles, redirectTo = "/" }: ProtectedRouteProps) => {
  const { userRole } = usePermissions();

  if (!userRole || !requiredRoles.includes(userRole)) {
    return <Navigate to={redirectTo} />;
  }

  return <Outlet />;
};

export { ProtectedRoute, type ProtectedRouteProps };
