import { useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { type JwtPayload, jwtDecode } from "jwt-decode";

import useCookie from "@/hooks/useCookies";
import BaseLayout from "@/layouts/BaseLayout";
import { usePermissions } from "@/providers/PermissionsProvider";

const App = () => {
  const location = useLocation();
  const { isLoading, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const [value, setCookie] = useCookie("token");
  const navigate = useNavigate();
  const { setPermissions, setIsLoading } = usePermissions();

  useEffect(() => {
    let accessToken: string | null = null;
    const getUserMetaData = async () => {
      try {
        accessToken = await getAccessTokenSilently();
        setCookie(accessToken);
        const decodedToken = jwtDecode<JwtPayload & { permissions: string[] }>(accessToken);
        setPermissions(decodedToken.permissions);
      } catch (error) {
        console.error("Auth0ProviderError: ", error);
      } finally {
        setIsLoading(false);
      }
    };
    getUserMetaData().then();

    // we only want to run this once if the current router is the root router
    if (value && (location.pathname === "/" || location.pathname === "/login")) {
      navigate("/operations");
    }
  }, [getAccessTokenSilently, setCookie, value, navigate, location, setPermissions, setIsLoading]);

  if (isLoading && !value) {
    return <div>Carregando...</div>;
  }

  if (!isAuthenticated && !isLoading && !value) {
    return <Navigate to="/login" replace />;
  }

  return <BaseLayout />;
};

export default App;

export { App };
